const cpObj = inObject => {
  let outObject, value, key;

  if (
    typeof inObject !== "object" ||
    inObject === null ||
    inObject instanceof File
  ) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = cpObj(value);
  }

  return outObject;
};

const sortObj = (a, b, key) => {
  if (typeof a[key] === "string") {
    a = a[key].toLowerCase();
  } else {
    a = a[key];
  }
  if (typeof b[key] === "string") {
    b = b[key].toLowerCase();
  } else {
    b = b[key];
  }
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export { cpObj, sortObj };
