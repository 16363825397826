import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../styles/control.css";
import ArrowCircle from "./ArrowCircle";

function CamControl({ host, frame, className }) {
  const [panSpeed, setPanSpeed] = useState(12);
  const [tiltSpeed, setTiltSpeed] = useState(10);
  const [focusSpeed, setFocusSpeed] = useState(4);
  const [focusLock, setFocusLock] = useState(null);

  const buildUrl = (cmd, speed) =>
    `http://${host}/cgi-bin/ptzctrl.cgi?ptzcmd&${cmd}${speed ? speed : ""}`;

  const [href, setHref] = useState(null);
  const link = useRef();

  const move = (dir, zoom = null) => {
    let speed = null;
    if (zoom === "m") speed = `&${panSpeed}&${tiltSpeed}`;
    else if (zoom === "z") speed = `&${focusSpeed}`;
    setHref(buildUrl(dir, speed));
  };

  useEffect(() => {
    link.current.click();
  }, [href]);

  const actions = [
    {
      onMouseDown: () => move("right", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
    {
      onMouseDown: () => move("rightdown", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
    {
      onMouseDown: () => move("down", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
    {
      onMouseDown: () => move("leftdown", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
    {
      onMouseDown: () => move("left", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
    {
      onMouseDown: () => move("leftup", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
    {
      onMouseDown: () => move("up", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
    {
      onMouseDown: () => move("rightup", "m"),
      onMouseUp: () => move("ptzstop", "m"),
    },
  ];

  return (
    <div className={className}>
      <a ref={link} target={frame} href={href} style={{ display: "none" }}>
        Submit
      </a>
      <Container
        fluid
        style={{ maxWidth: "200px" }}
        className="border rounded p-1 d-flex flex-column justify-content-center align-items-center mb-2"
      >
        <Form.Group controlId="move-speed" className="mb-1">
          <Form.Label>Speed</Form.Label>
          <Form.Range
            min={1}
            max={24}
            value={panSpeed}
            onChange={e => {
              let value = Number(e.target.value);
              setPanSpeed(value);
              setTiltSpeed(Math.floor((value * 5) / 6) || 1);
            }}
          />
        </Form.Group>
        <ArrowCircle
          className="mb-1"
          actions={actions}
          middle={{
            onClick: () => {
              setHref(buildUrl("home"));
            },
          }}
        />
      </Container>
      <Container
        fluid
        style={{ maxWidth: "200px" }}
        className="border rounded p-1 d-flex flex-column justify-content-center align-items-center"
      >
        <Form.Group controlId="move-speed" className="mb-1">
          <Form.Label>Speed</Form.Label>
          <Form.Range
            min={1}
            max={7}
            value={focusSpeed}
            onChange={e => setFocusSpeed(Number(e.target.value))}
          />
        </Form.Group>
        <div className="mb-1 w-100">
          <Form.Label>Zoom</Form.Label>
          <Row className="g-1">
            <Col className="d-grid">
              <Button
                variant="outline-light"
                onMouseDown={() => move("zoomin", "z")}
                onMouseUp={() => move("zoomstop", "z")}
              >
                in
              </Button>
            </Col>
            <Col className="d-grid">
              <Button
                variant="outline-light"
                onMouseDown={() => move("zoomout", "z")}
                onMouseUp={() => move("zoomstop", "z")}
              >
                out
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mb-1 w-100">
          <Form.Label>Focus</Form.Label>
          <div
            className="grid gap-2"
            style={{ "--bs-columns": 2, "--bs-rows": 2 }}
          ></div>
          <Row className="row-cols-2 g-1">
            <Col className="d-grid">
              <Button
                variant="outline-light"
                onMouseDown={() => move("focusin", "z")}
                onMouseUp={() => move("focusstop", "z")}
              >
                in
              </Button>
            </Col>
            <Col className="d-grid">
              <Button
                variant="outline-light"
                onMouseDown={() => move("focusout", "z")}
                onMouseUp={() => move("focusstop", "z")}
              >
                out
              </Button>
            </Col>
            <Col className="d-grid">
              <Button
                variant="outline-light"
                className={focusLock === true ? "active" : ""}
                onClick={() => {
                  move("lock_mfocus");
                  setFocusLock(true);
                }}
              >
                LOCK
              </Button>
            </Col>
            <Col className="d-grid">
              <Button
                variant="outline-light"
                className={focusLock === false ? "active" : ""}
                onClick={() => {
                  move("unlock_mfocus");
                  setFocusLock(false);
                }}
              >
                UNLOCK
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default CamControl;
