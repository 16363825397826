import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function BtnForm({ data, change }) {
  return (
    <>
      <Row>
        <Col
          md
          as="h3"
          className="d-flex align-items-center form-switch mb-md-0"
          style={{ padding: "0 calc(var(--bs-gutter-x) * .5)" }}
        >
          <input
            id={`btn-${data.id}-display`}
            type="checkbox"
            className="form-check-input me-2 ms-0"
            role="switch"
            checked={data.display}
            onChange={e => change("display", e.target, 0)}
          />
          <label
            htmlFor={`btn-${data.id}-display`}
            className="form-check-label"
          >{`Preset ${data.preset}`}</label>
        </Col>
        <Col md>
          <Form.Group controlId={`btn-${data.id}-name`}>
            <Form.Label visuallyHidden>Name</Form.Label>
            <Form.Control
              type="text"
              value={data.name}
              onChange={e => change("name", e.target, -1)}
              placeholder="Name"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default BtnForm;
