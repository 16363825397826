import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNote } from "../notifications";
import BtnGrid from "./BtnGrid";
import CamControl from "./CamControl";

function CamBtn({ data, idx, frame }) {
  const [host, setHost] = useState(data.ip);
  const [ctrl, setCtrl] = useState(true);
  const [edit, setEdit] = useState(false);
  const { notify } = useNote();

  useEffect(() => {
    setHost(data.ip);
  }, [data.ip]);

  const buildUrl = (cmd, pos) =>
    `http://${host}/cgi-bin/ptzctrl.cgi?ptzcmd&${cmd}&${pos}`;

  const [href, setHref] = useState(null);
  const link = useRef();

  useEffect(() => {
    link.current.click();
    setHref(null);
  }, [href]);

  const handleClick = () => {
    if (edit)
      return num => {
        setHref(buildUrl("posset", num));
        notify(`Preset ${num} erfolreich gesetzt.`, {
          type: "success",
          duration: 1_000,
          dismissible: true,
        });
      };
    return num => setHref(buildUrl("poscall", num));
  };

  return (
    <>
      <div className="d-flex">
        <Button className="me-auto" onClick={() => setCtrl(!ctrl)}>
          Steuerung {ctrl ? "ausblenden" : "einblenden"}
        </Button>
        <Button
          variant={edit ? "success" : "primary"}
          onClick={() => setEdit(!edit)}
        >
          {edit ? "Fertig" : "Presets setzen"}
        </Button>
      </div>
      <a ref={link} target={frame} href={href} style={{ display: "none" }}>
        Submit
      </a>
      <div className="d-flex mt-2">
        {ctrl ? (
          <CamControl frame={frame} host={host} className="me-2"></CamControl>
        ) : null}
        {edit ? (
          <div className="flex-grow-1 rounded bg-danger p-1 bg-opacity-50">
            <BtnGrid data={data.btns} onClick={handleClick()} />
          </div>
        ) : (
          <BtnGrid data={data.btns} onClick={handleClick()} />
        )}
      </div>
    </>
  );
}

export default CamBtn;
