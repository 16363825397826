import { useCamStorage } from "../hooks/useCamStorage";
import { useNote } from "../notifications";
import Upload from "./Upload";

function ImportCam(props) {
  const { import: imp } = useCamStorage();
  const { notify } = useNote();

  const handleImport = data => {
    imp(data.cam || []);
    notify("Import erfolgreich");
  };

  return (
    <Upload
      {...props}
      title="Import"
      triggerProps={{ className: "btn btn-primary" }}
      onUpload={handleImport}
      onError={msg => notify(msg, { type: "danger" })}
    />
  );
}

export default ImportCam;
