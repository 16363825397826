import { useReducer } from "react";
import { hfc, resolvePath } from "../form";
import { cpObj } from "../misc";

function reducer(state, action) {
  let newState = cpObj(state);
  switch (action.type) {
    case "CHANGE":
      newState = resolvePath(
        action.key,
        newState,
        hfc(action.elem, action.variant)
      );
      return newState;
    case "RESET":
      if (typeof action.data === "function") action.data = action.data();
      newState = action.data;
      return newState;
    default:
      return state;
  }
}

function useForm(fields) {
  const [data, dispatch] = useReducer(reducer, fields);

  const change = (key, elem, variant) =>
    dispatch({ type: "CHANGE", key, elem, variant });

  const reset = data => dispatch({ type: "RESET", data });

  return { data, change, reset };
}

export default useForm;
