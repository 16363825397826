import { NavLink } from "react-router-dom";

function BNavBrand({ children, ...rest }) {
  return (
    <NavLink className="navbar-brand" {...rest}>
      {children}
    </NavLink>
  );
}

export default BNavBrand;
