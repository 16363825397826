import { useEffect, useRef, useState } from "react";

function Download({
  title = "Download",
  data = {},
  filename = "download",
  triggerProps = {},
  ...props
}) {
  const [url, setUrl] = useState(null);
  const link = useRef();

  useEffect(() => {
    link.current.click();
    // free up storage--no longer needed.
    URL.revokeObjectURL(url);
    setUrl(null);
  }, [url]);

  const download = e => {
    e.preventDefault();
    // Prepare the file
    let output = JSON.stringify(data, null, 2);

    // Download it
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    setUrl(fileDownloadUrl);
  };

  return (
    <div {...props} style={{ display: "inline-block" }}>
      <button {...triggerProps} onClick={download}>
        {title}
      </button>
      <a
        style={{ display: "none" }}
        download={`${filename}.json`}
        href={url}
        ref={link}
      >
        download it
      </a>
    </div>
  );
}

export default Download;
