import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import BNavLink from "./BNavLink";
import BNavBrand from "./BNavBrand";

function NavBar() {
  return (
    <Navbar
      bg="primary"
      expand="sm"
      variant="dark"
      id="main-nav"
      className="border-bottom"
    >
      <Container fluid>
        <BNavBrand to="/">Cam Control</BNavBrand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto mb-2 mb-sm-0">
            <BNavLink to="/setup">Setup</BNavLink>
            <a className="nav-link" href="/old.html">
              Alte Version
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
