import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Download from "./Download";
import EditBtn from "./EditBtn";
import { useCamStorage } from "../hooks/useCamStorage";
import { useState } from "react";
import { useNote } from "../notifications";
import "../styles/addCam.css";

function EditCam({ data, idx }) {
  const { change, rmCam, sortCam, addBtn } = useCamStorage();
  const { notify } = useNote();
  const [ip, setIp] = useState(data.ip);

  const validateIp = ip => {
    const re = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
    if (re.exec(ip)) {
      change([idx, "ip"], { value: ip }, -1);
      return;
    }
    notify("Ungültige IP-Adresse", { type: "danger" });
    setIp(data.ip);
  };

  return (
    <div className="border rounded p-2 my-2 dark">
      <h2>
        Kamera <span className="fw-bold fst-italic">{data.name}</span>
      </h2>
      <Form>
        <Form.Group className="mb-3" controlId={`cam-name-${data.id}`}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={data.name}
            onChange={e => change([idx, "name"], e.target, -1)}
            onBlur={sortCam}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId={`cam-ip-${data.id}`}>
          <Form.Label>IP-Adresse</Form.Label>
          <Form.Control
            type="text"
            pattern="^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$"
            title="IP-Adresse"
            value={ip}
            onChange={e => setIp(e.target.value)}
            onBlur={e => validateIp(e.target.value)}
          />
        </Form.Group>
      </Form>
      <EditBtn
        btns={data.btns}
        path={[idx, "btns"]}
        change={change}
        addPreset={() => addBtn(idx)}
      />
      <div className="d-flex justify-content-end">
        <Button
          className="me-auto"
          onClick={() =>
            change([idx, "display"], { checked: !data.display }, 0)
          }
        >
          {data.display ? "Aus" : "Ein"}blenden
        </Button>
        <Download
          className="ms-1"
          title="Export"
          triggerProps={{ className: "btn btn-primary" }}
          data={{ cam: data }}
          filename={`cam_control_${data.name.replaceAll(" ", "_")}_${new Date()
            .toISOString()
            .split("T")[0]
            .replaceAll("-", "_")}`}
        />
        <Button variant="danger" className="ms-1" onClick={() => rmCam(idx)}>
          Löschen
        </Button>
      </div>
    </div>
  );
}

export default EditCam;
