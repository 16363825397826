import Notification from "./Notification";
import { useNote } from "./useNotification";
import "./note.css";

function NotificationContainer() {
  const { notes } = useNote();
  return (
    <div className="notification-container">
      {notes.map(note => (
        <Notification key={note.id} {...note} />
      ))}
    </div>
  );
}

export default NotificationContainer;
