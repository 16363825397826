import { NavLink } from "react-router-dom";

function BNavLink({ children, ...rest }) {
  return (
    <NavLink className="nav-link" {...rest}>
      {children}
    </NavLink>
  );
}

export default BNavLink;
