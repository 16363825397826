import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import useForm from "../hooks/useForm";
import { v4 as uuid4 } from "uuid";
import "../styles/addCam.css";

function AddCam({ show, setShow, submit }) {
  const { data, change, reset } = useForm({
    id: uuid4(),
    name: "",
    ip: "",
    display: true,
    btns: [],
  });

  const resetForm = () =>
    reset({ id: uuid4(), name: "", ip: "", display: true, btns: [] });

  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  const submitForm = e => {
    e.preventDefault();
    submit(data);
    resetForm();
    setShow(false);
  };

  return (
    <Modal
      size="lg"
      centered
      fullscreen="md-down"
      scrollable
      className="modal-dark"
      backdropClassName="modal-backdrop-dark"
      backdrop="static"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Kamera{" "}
          <b>
            <i>{data.name}</i>
          </b>{" "}
          hinzufügen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="add-cam" onSubmit={submitForm}>
          <Form.Group className="mb-3" controlId="cam-name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              required
              value={data.name}
              onChange={e => change("name", e.target, -1)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="cam-ip">
            <Form.Label>IP-Adresse</Form.Label>
            <Form.Control
              type="text"
              required
              pattern="^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$"
              title="IP-Adresse"
              value={data.ip}
              onChange={e => change("ip", e.target, -1)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" form="add-cam" type="submit">
          Hinzufügen
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddCam;
