function removeCam(cams, id) {
  let idx = cams.findIndex(elem => elem.id === id);
  if (idx !== -1) {
    cams.splice(idx, 1);
  }
  return cams;
}

function validateBtn(btn, presets = []) {
  let keys = Object.keys(btn);
  if (!keys.includes("id")) return false;
  if (!keys.includes("name")) return false;
  if (!keys.includes("display")) btn.display = true;
  if (!keys.includes("preset")) return false;
  btn.preset = parseInt(btn.preset);
  if (Number.isNaN(btn.preset)) return false;
  if (btn.preset < 1 || btn.preset > 89) return false;
  if (presets.includes(btn.preset)) return false;
  presets.push(btn.preset);
  return true;
}

function validateCam(cam) {
  let keys = Object.keys(cam);
  if (!keys.includes("id")) return false;
  if (!keys.includes("ip")) return false;
  if (!keys.includes("name")) return false;
  if (!keys.includes("display")) cam.display = true;
  if (!keys.includes("btns")) cam.btns = [];
  let presets = [];
  let invalid = [];
  for (let idx in cam.btns) {
    if (!validateBtn(cam.btns[idx], presets)) {
      invalid.unshift(idx);
    }
  }
  for (let idx of invalid) {
    cam.btns.splice(idx, 1);
  }
  return true;
}

export { removeCam, validateCam };
