import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CamBtn from "../components/CamBtn";
import { useCamStorage } from "../hooks/useCamStorage";
import usePageTitle from "../hooks/usePageTitle";
import "../styles/home.css";

function HomePage() {
  usePageTitle();
  const { storage: data } = useCamStorage();
  return (
    <Container fluid className="dark">
      <Tabs>
        {data
          .filter(elem => elem.display)
          .map((elem, idx) => (
            <Tab
              key={elem.id}
              className="border border-top-0 rounded-bottom p-3"
              eventKey={`tab-${elem.id}`}
              title={elem.name}
            >
              <CamBtn data={elem} frame="result" idx={idx} />
            </Tab>
          ))}
      </Tabs>
      <iframe
        title="Form response"
        name="result"
        frameBorder="0"
        style={{ display: "none" }}
      ></iframe>
    </Container>
  );
}

export default HomePage;
