import { createContext, useContext, useReducer } from "react";
import { hfc, resolvePath } from "../form";
import { cpObj, sortObj } from "../misc";
import StorageService from "../services/StorageService";
import { removeCam, validateCam } from "./camStoreUtil";
import { v4 as uuid4 } from "uuid";

const storageContext = createContext();

function ProvideCamStorage({ storageKey, children }) {
  const storage = useProvideStorage(storageKey);
  return (
    <storageContext.Provider value={storage}>
      {children}
    </storageContext.Provider>
  );
}

const useCamStorage = () => {
  return useContext(storageContext);
};

function camReducer(state, action) {
  let newState = cpObj(state);
  switch (action.type) {
    case "IMPORT":
      if (Array.isArray(action.data)) {
        let invalid = [];
        for (let idx in action.data) {
          if (!validateCam(action.data[idx])) {
            invalid.unshift(idx);
          }
        }
        for (let idx of invalid) {
          action.data.splice(idx, 1);
        }
        for (let id of action.data.map(elem => elem.id)) {
          removeCam(newState, id);
        }
        newState = newState.concat(action.data);
      } else {
        if (!validateCam(action.data)) {
          return state;
        }
        removeCam(newState, action.data.id);
        newState.push(action.data);
      }
      newState.sort((a, b) => sortObj(a, b, "name"));
      StorageService.set({ key: action.key }, newState);
      return newState;
    case "ADD_CAM":
      newState.push(action.data);
      newState.sort((a, b) => sortObj(a, b, "name"));
      StorageService.set({ key: action.key }, newState);
      return newState;
    case "REMOVE_CAM":
      newState.splice(action.idx, 1);
      StorageService.set({ key: action.key }, newState);
      return newState;
    case "CHANGE":
      newState = resolvePath(
        action.path,
        newState,
        hfc(action.elem, action.variant)
      );
      StorageService.set({ key: action.key }, newState);
      return newState;
    case "SORT_CAM":
      newState.sort((a, b) => sortObj(a, b, "name"));
      StorageService.set({ key: action.key }, newState);
      return newState;
    case "ADD_BTN":
      newState[action.idx].btns.push({
        id: uuid4(),
        name: "",
        display: true,
        preset: newState[action.idx].btns.length + 1,
      });
      StorageService.set({ key: action.key }, newState);
      return newState;
    default:
      return state;
  }
}

function useProvideStorage(key) {
  const init = () => {
    let data = StorageService.get({ key });
    return data || [];
  };

  const [storage, dispatch] = useReducer(camReducer, undefined, init);

  const get = () => storage;

  const _import = data => dispatch({ type: "IMPORT", key, data });

  const addCam = data => dispatch({ type: "ADD_CAM", key, data });

  const sortCam = () => dispatch({ type: "SORT_CAM", key });

  const rmCam = idx => {
    if (!window.confirm(`Kamera ${storage[idx].name} löschen?`)) return;
    dispatch({ type: "REMOVE_CAM", key, idx });
  };

  const change = (path, elem, variant) =>
    dispatch({ type: "CHANGE", key, path, elem, variant });

  const addBtn = idx => dispatch({ type: "ADD_BTN", key, idx });

  return {
    storage,
    get,
    import: _import,
    addCam,
    rmCam,
    sortCam,
    change,
    addBtn,
  };
}

export { ProvideCamStorage, useCamStorage };
