import { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import BtnForm from "./BtnForm";

function EditBtn({ btns, path, change, addPreset }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="mb-3">
      <Form.Label>
        Preset Buttons{" "}
        <Button
          variant="outline-light"
          onClick={() => setOpen(!open)}
          aria-controls="pre-btn"
          aria-expanded={open}
        >
          {open ? "Einklappen" : "Ausklappen"}
        </Button>
      </Form.Label>
      <Collapse in={open}>
        <div id="pre-btn" className="border rounded">
          <div className="m-2">
            {btns.map((elem, idx) => (
              <div key={elem.id}>
                <BtnForm
                  data={elem}
                  change={(key, elem, type) =>
                    change([...path, idx, key], elem, type)
                  }
                />
                {idx !== btns.length - 1 ? <hr /> : null}
              </div>
            ))}
            {btns.length > 0 && btns.length < 89 ? <hr /> : null}
            {btns.length < 89 ? (
              <div className="d-grid">
                <Button variant="outline-light" onClick={addPreset}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    style={{ height: "20px", width: "20px" }}
                  >
                    <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                  </svg>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default EditBtn;
