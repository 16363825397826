import React, { createContext, useContext, useReducer } from "react";
import NotificationContainer from "./NotificationContainer";
import { v4 as uuid4 } from "uuid";

const noteContext = createContext();

function ProvideNote({ children }) {
  const note = useProvideNote();
  return (
    <noteContext.Provider value={note}>
      {children}
      <NotificationContainer />
    </noteContext.Provider>
  );
}

const useNote = () => {
  return useContext(noteContext);
};

function noteReducer(state, action) {
  let newState = [];
  switch (action.type) {
    case "ADD":
      newState = [...state];
      newState.unshift(action.note);
      return newState;
    case "CLOSE":
      newState = state.map(note => {
        if (note.id !== action.id) return note;
        return {
          ...note,
          requestClose: true,
        };
      });
      return newState;
    case "CLOSE_ALL":
      newState = state.map(note => {
        return { ...note, requestClose: true };
      });
      return newState;
    // actually fully remove the toast
    case "REMOVE":
      newState = state.filter(note => {
        return note.id !== action.id;
      });
      return newState;
    default:
      return state;
  }
}

function useProvideNote() {
  const [notes, dispatch] = useReducer(noteReducer, []);

  const createNote = (
    message,
    { duration = 5_000, type = "info", dismissible = false }
  ) => {
    const noteId = `toast-${uuid4()}`;

    return {
      id: noteId,
      message,
      showing: true,
      duration,
      type,
      dismissible,
      onRequestRemove: () => dispatch({ type: "REMOVE", id: noteId }),
    };
  };

  const closeNote = id => dispatch({ type: "CLOSE", id });

  const closeAll = () => dispatch({ type: "CLOSE_ALL" });

  const notify = (message, options = {}) => {
    const note = createNote(message, options);
    dispatch({ type: "ADD", note });

    return note.id;
  };

  return { notes, notify, closeNote, closeAll };
}

export { ProvideNote, useNote };
