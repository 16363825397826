import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";

function NotFoundPage() {
  usePageTitle("Nicht gefunden");
  return (
    <>
      <h1>Seite nicht gefunden</h1>
      <Button as={Link} to="/">
        Zurück zur Steuerung
      </Button>
    </>
  );
}

export default NotFoundPage;
