import React, { useEffect, useRef, useState } from "react";
import { useTransition, animated } from "react-spring";
import Alert from "./Alert";
import { useTimeout } from "./useTimeout";

function Notification({
  id,
  message,
  type = "info",
  dismissible,
  onRequestRemove,
  requestClose = false,
  duration = 30_000,
}) {
  const container = useRef(null);
  const [timeout, setTimeout] = useState(duration);
  const [localShow, setLocalShow] = useState(true);

  const close = () => setLocalShow(false);
  useTimeout(close, timeout);

  const animation = {
    config: { mass: 1, tension: 185, friction: 26 },
    from: {
      opacity: 1,
      transform: "scale(1)",
    },
    enter: () => next =>
      next({
        opacity: 1,
        transform: "scale(1)",
      }),
    leave: {
      opacity: 0,
      transform: "scale(0.5)",
    },
    onRest: () => {
      if (!localShow) onRequestRemove();
    },
  };

  const transition = useTransition(localShow, animation);

  const onMouseEnter = () => setTimeout(null);

  const onMouseLeave = () => setTimeout(duration);

  useEffect(() => {
    if (requestClose) setLocalShow(false);
  }, [requestClose]);

  function renderMessage() {
    if (typeof message === "string" || React.isValidElement(message)) {
      return (
        <Alert
          id={id}
          message={message}
          type={type}
          onClose={dismissible ? close : null}
        />
      );
    }

    if (typeof message === "function") {
      return message({
        id,
        onClose: close,
      });
    }

    return null;
  }

  return (
    <>
      {transition(
        ({ opacity, height, transform }, item) =>
          item && (
            <animated.div
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={{
                opacity: opacity,
                height: height,
              }}
            >
              <animated.div
                style={{
                  transform: transform,
                  pointerEvents: "auto",
                }}
                ref={container}
              >
                {renderMessage()}
              </animated.div>
            </animated.div>
          )
      )}
    </>
  );
}

export default Notification;
