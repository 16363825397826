import { cpObj } from "./misc";

function hfc(elem, typ) {
  let value = "";
  switch (typ) {
    case 0: // checkbox
      value = elem.checked;
      break;
    case 1: // checkbox -> number old: 4
      value = Number(elem.checked);
      break;
    case 2: // select -> value old: 3
      console.log(elem);
      value = !Number.isNaN(Number(elem.value))
        ? Number(elem.value)
        : elem.value;
      console.log(value);
      break;
    case 3: // select or select multiple -> Array old: 1
      value = Array.from(elem.selectedOptions).map(option =>
        !Number.isNaN(Number(option.value))
          ? Number(option.value)
          : option.value
      );
      break;
    case 4: // input to Number if possible old: 2
      value = elem.value;
      if (!Number.isNaN(Number(value))) value = Number(value);
      break;
    case 5: // file
      value = elem.files[0];
      break;
    case 6: // selectEditor single
      value = elem;
      break;
    default:
      value = elem.value;
  }
  return value;
}

const resolvePath = (path, data, cData) => {
  let mod = cpObj(data);
  data = mod;
  if (typeof path === "string") {
    mod[path] = cData;
  } else {
    path = cpObj(path);
    for (let i = 0; i < path.length; i++) {
      if (typeof path[i] === "string") {
        if (i !== path.length - 1) mod = mod[path[i]];
        else {
          mod[path[i]] = cData;
        }
      } else {
        mod = mod[path[i]];
      }
    }
  }
  return data;
};

export { hfc, resolvePath };
