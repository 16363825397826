import { useRef } from "react";

function Upload({
  title = "Upload",
  onUpload,
  triggerProps = {},
  onError,
  ...props
}) {
  if (!onUpload) onUpload = data => console.log(data);
  if (!onError) onError = msg => console.warn(msg);
  const input = useRef();

  const triggerUpload = e => {
    e.preventDefault();
    input.current.click();
  };

  /**
   * Process the file within the React app. We're NOT uploading it to the server!
   */
  const upload = e => {
    const fileObj = e.target.files[0];
    if (fileObj.type !== "application/json") {
      onError("Ungültiger Dateityp");
      return;
    }
    const reader = new FileReader();

    let fileLoaded = e => {
      // e.target.result is the file's content as text
      try {
        let data = JSON.parse(e.target.result);
        onUpload(data);
      } catch {
        onError("Invalid JSON");
      }
    };

    reader.onload = fileLoaded;
    reader.readAsText(fileObj);
  };

  return (
    <div {...props} style={{ display: "inline-block" }}>
      <button {...triggerProps} onClick={triggerUpload}>
        {title}
      </button>
      <input
        type="file"
        style={{ display: "none" }}
        multiple={false}
        accept=".json,application/json"
        onChange={upload}
        ref={input}
      />
    </div>
  );
}

export default Upload;
