import Arrow from "./Arrow";

function ArrowBtn({ rot, onClick, className, ...props }) {
  return (
    <button
      {...props}
      className={`btn btn-quad deg-${rot}${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <Arrow />
    </button>
  );
}

export default ArrowBtn;
