const DEFAULT_STORAGE = "localStorage";
const DEFAULT_CRYPT = false;

const utf8_to_b64 = str => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

const b64_to_utf8 = str => {
  return decodeURIComponent(escape(window.atob(str)));
};

const get = ({ key, storage = DEFAULT_STORAGE, decode = DEFAULT_CRYPT }) => {
  let data = window[storage].getItem(key);
  return JSON.parse(decode ? b64_to_utf8(data) : data);
};

const set = (
  { key, storage = DEFAULT_STORAGE, encode = DEFAULT_CRYPT },
  data
) => {
  data = JSON.stringify(data);
  window[storage].setItem(key, encode ? utf8_to_b64(data) : data);
};

const del = ({ key, storage = DEFAULT_STORAGE }) => {
  window[storage].removeItem(key);
};

const StorageService = { get, set, del };

export default StorageService;
