import { Route, Routes } from "react-router-dom";
import NavBar from "./components/Navbar";
import { ProvideCamStorage } from "./hooks/useCamStorage";
import { ProvideNote } from "./notifications";
import NotFoundPage from "./pages/404";
import HomePage from "./pages/Home";
import SetupPage from "./pages/Setup";

function App() {
  return (
    <>
      <NavBar />
      <ProvideNote>
        <main className="p-4">
          <ProvideCamStorage storageKey="cams">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="setup" element={<SetupPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ProvideCamStorage>
        </main>
      </ProvideNote>
    </>
  );
}

export default App;
