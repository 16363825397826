import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function BtnGrid({ data, onClick }) {
  return (
    <Row className="row row-cols-auto g-1">
      {data
        .filter(elem => elem.display)
        .map((elem, idx) => (
          <Col key={elem.id}>
            <Button
              variant="outline-light btn-quad-lg text-break"
              onClick={() => onClick(elem.preset)}
            >
              {elem.name ? elem.name : elem.preset}
            </Button>
          </Col>
        ))}
    </Row>
  );
}

export default BtnGrid;
