import React from "react";

function Alert({ id, message, type, onClose }) {
  return (
    <div
      id={id}
      className={`alert alert-${type}${onClose ? " alert-dismissible" : ""}`}
    >
      {message}
      {onClose && <Close onClose={onClose} />}
    </div>
  );
}

const Close = ({ onClose }) => (
  <button
    className="btn-close"
    type="button"
    aria-label="Close"
    onClick={onClose}
  />
);

export default Alert;
