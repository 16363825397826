import { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import AddCam from "../components/AddCam";
import Download from "../components/Download";
import EditCam from "../components/EditCam";
import ImportCam from "../components/ImportCam";
import { useCamStorage } from "../hooks/useCamStorage";
import usePageTitle from "../hooks/usePageTitle";

function SetupPage() {
  usePageTitle("Setup");
  const { storage: data, addCam } = useCamStorage();
  const [add, setAdd] = useState(false);

  return (
    <Container fluid>
      <h1 className="mb-3">Setup</h1>
      <div className="d-flex align-items-center my-3">
        <Button onClick={() => setAdd(true)}>Kamera hinzufügen</Button>
        <ImportCam className="ms-2" />
        <Download
          className="ms-2"
          title="Export all"
          triggerProps={{ className: "btn btn-primary" }}
          data={{ cam: data }}
          filename={`cam_control_all_${new Date()
            .toISOString()
            .split("T")[0]
            .replaceAll("-", "_")}`}
        />
      </div>
      <AddCam show={add} setShow={setAdd} submit={addCam} />
      <div>
        {data.map((elem, idx) => (
          <EditCam key={elem.id} idx={idx} data={elem} />
        ))}
      </div>
    </Container>
  );
}

export default SetupPage;
